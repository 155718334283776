import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'

function Registration() {
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h3 className="card-title border-bottom">Registration process for NetCrypt-2025</h3>

                <h5 className="card-title">Kindly do the needful accordingly for registration:</h5>

                <ul>
                  <li>
                    <p className="card-text my-3" style={{textAlign:"justify"}}>Check your CMT registered email ID for acceptance.</p>
                  </li>

                  <li>
                    <p className="card-text my-3" style={{textAlign:"justify"}}>Need to check the comments of reviewers for improvement of the paper.</p>
                  </li>

                  <li>
                    <p className="card-text my-3" style={{textAlign:"justify"}}>Update the paper accordingly and prepare the final camera-ready copy (CRC), check the author's details, emails, organization, figures visibility, References, results X-axis name and Y-axis name etc. and give the name "CRC_PID" to file. It should be doc/docx file only.</p>
                  </li>

                  <li>
                    <p className="card-text my-3" style={{textAlign:"justify"}}>Do the payment according to the category of registration and write "For JNU NETCRYPT" in the payment reference (during the payment process).  Take a copy of payment slip in PDF or JPEG or PNG. Give the name Payment_PID to file.</p>
                  </li>

                  <li>
                    <p className="card-text my-3" style={{textAlign:"justify"}}>Fill out the registration form for the presentation. All information needs to be verified twice.  NAME, MOBILE, WHATSAPPS NO, email ID, Payment Details.</p>
                  </li>

                  <li>
                    <p className="card-text my-3" style={{textAlign:"justify"}}>Wait for the paper presentation schedule. Check your email or Conference Website.</p>
                  </li>
                </ul>

                <h3 className="card-title border-bottom">Early Bird Registration fees</h3>
                <p className="card-text" style={{textAlign:"justify"}}>The registration fees cover the cost of food and beverages, a bag, a brochure, schedules, a pen, and a note pad. <strong>The final registration deadline is April 15, 2025.</strong></p>

                <div className="container table-responsive">
                <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        {/* <th scope="col">Non IEEE Member</th> */}
                          
                          
                          <th scope="col" style={{fontSize:"9px"}}><p>Indian (In-person Presentation)</p></th>
                          <th scope="col" style={{fontSize:"9px"}}><p>Indian (Online Presentation)</p></th>
                          <th scope="col" style={{fontSize:"9px"}}><p>International</p></th>                     
                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{fontSize:"12px"}}>
                        <th scope="row"><p>Non-IEEE Professional</p></th>
                        <td>&#8377; 10,500 </td>
                        <td>&#8377; 11,550 </td>
                        <td>&#x24; 350 </td>
                   
                      </tr>

                      <tr style={{fontSize:"12px"}}>
                        <th scope="row">  IEEE Professional Members</th>
                        <td>&#8377; 9500</td>
                        <td>&#8377; 10,450</td>
                        <td>&#x24; 250 </td>
                        
                        
                        
                      </tr>

                      <tr style={{fontSize:"12px"}}>
                        <th scope="row">Non-IEEE Students</th>
                        <td>&#8377; 7500</td>
                        <td>&#8377; 8250</td>
                        <td>&#x24; 200 </td>
                  
                        
                        
                      </tr>

                      <tr style={{fontSize:"12px"}}>
                        <th scope="row">  IEEE Student Members</th>
                        
                        <td>&#8377; 6000</td>
                        <td>&#8377; 6600</td>
                        <td>&#x24; 180 </td>
                      
                        
                        
                      </tr>

                      <tr style={{fontSize:"12px"}}>
                        <th scope="row">Co-Author</th>
                        
                        <td>&#8377; 1180</td>
                        <td>&#8377; 1298</td>
                        <td>&#x24; 30 </td>
                      </tr>

                      <tr style={{fontSize:"12px"}}>
                        <th scope="row">Attendees and Poster Presenter</th>
                        
                        <td>&#8377; 2000</td>
                        <td>&#8377; 2200</td>
                        <td>&#x24; 40 </td>
                      </tr>

                    </tbody>
                </table>
                </div>

                <ul>
                  <li>
                    <p className="card-text my-5" style={{textAlign:"justify"}}>Attendees who participate in person will receive a special surprise along with the conference registration kit</p>
                  </li>
                  <li>
                    <p className="card-text" style={{textAlign:"justify"}}>Registrations after the deadline will incur an <strong>additional fee of ₹1000 for Indian participants and $10 for international participants.</strong> </p>
                  </li>
                </ul>

                <h3 className="card-title border-bottom">For Least Developed Countries (LDCs)</h3>

                <ul>
                  <li>
                    <h5 className="card-title my-5" style={{"textAlign":"justify"}}>Authors from Least Developed Countries (LDCs) may be eligible for special discounts on registration fees based on their economic conditions.</h5>

                  </li>

                  <li>
                    <h5 className="card-title border-bottom my-5" style={{"textAlign":"justify"}}>Authors from Least Development Countries (LDCs) are required to send an email to chair@netcrypt.org.in to request the special discount.</h5>

                  </li>
                </ul>

                

                

                <h3 className="card-title border-bottom my-5">Account Details for Indian Authors</h3>


                <table className="table table-striped table-hover my-5">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        {/* <th scope="col">Non IEEE Member</th> */}
                        
                  
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Beneficary Name</th>
                        <td>JNU Sponsored Seminar/Conference</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">  Name of Bank</th>
                        <td>State Bank of India</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Branch Name</th>
                        <td>JNU Branch</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Account Number</th>
                        <td>35404650496</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">  IFSC Code</th>
                        <td>SBIN0010441</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Bank Branch MICR Code</th>
                        <td>110002428</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">  Complete address</th>
                        <td>JNU New Campus Branch near Godavari Hostel, JNU, New Delhi-110067</td>
                        
                      </tr>

                      


                      
                    </tbody>
                </table>


                <h3 className="card-title border-bottom my-5">Account Details for Forign Authors</h3>


                <table className="table table-striped table-hover my-5">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        {/* <th scope="col">Non IEEE Member</th> */}
                        
                  
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">INSTITUTIONS'S ACCOUNT Name</th>
                        <td>JNU SPONSORED FELLOWSHIP/SCHOLARSHIP A/c</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">  Name of Bank</th>
                        <td>State Bank of India</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Branch Name</th>
                        <td>JNU Branch</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Account Number</th>
                        <td>10596551089</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">  IFSC Code</th>
                        <td>SBIN0010441</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">  SWIFT Code</th>
                        <td> <strong>SBININBB548</strong> </td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Bank Branch MICR Code</th>
                        <td>110002428</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">  Complete address</th>
                        <td>JNU New Campus Branch near Godavari Hostel, JNU, New Delhi-110067</td>
                        
                      </tr>

                      


                      
                    </tbody>
                </table>
                
                {/* <table className="table table-striped table-hover my-5">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        <th scope="col">Non IEEE Member</th>
                        <th scope="col">IEEE Member</th>
                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Scientific committee members</th>
                        <td>&#8377; 7000 </td>
                        <td>&#8377; 6000</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Standard registration</th>
                        <td>&#8377; 6500</td>
                        <td>&#8377; 5500</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Students</th>
                        <td>&#8377; 6000</td>
                        <td>&#8377; 5000</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Companies/industries</th>
                        <td>&#8377; 7000</td>
                        <td>&#8377; 6000</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Additional paper (≥3)</th>
                        <td>&#8377; 6000</td>
                        <td>&#8377; 5000</td>
                  
                      </tr>
                    </tbody>
                </table> */}

                {/* <table className="table table-striped table-hover my-5">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        <th scope="col">International Non IEEE Member</th>
                        <th scope="col">International IEEE Member</th>
                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Scientific committee members</th>
                        <td>&#x24; 350 </td>
                        <td>&#x24; 300</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Standard registration</th>
                        <td>&#x24; 250</td>
                        <td>&#x24; 200</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Students</th>
                        <td>&#x24; 200</td>
                        <td>&#x24; 180</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Companies/industries</th>
                        <td>&#x24; 350</td>
                        <td>&#x24; 250</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Additional paper (≥3)</th>
                        <td>&#x24; 200</td>
                        <td>&#x24; 180</td>
                  
                      </tr>
                    </tbody>
                </table> */}
                
              </div>
            </div>
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Registration